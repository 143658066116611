<template>
  <div>
    <Datatable route="/user/liste" :fields="fields" titre="Liste des utilisateurs" :sort="sort">
      <template #action>
        <b-button v-if="$api.acl(23)" variant="success" class="btn-icon" href="/#/utilisateur/nouveau"><i class="fas fa-plus"></i></b-button>
      </template>
      <template #cell(id)="data">
        <router-link :to="'/utilisateur/detail/'+data.value">{{data.value}}</router-link>
      </template>
      <template #cell(role_id)="data">
        {{$api.getParam('UserRole', data.value).titre}}
      </template>
      <template #cell(status_id)="data">
        <b-button size="sm" :variant="$api.getParam('UserStatus', data.value).color">{{$api.getParam('UserStatus', data.value).titre}}</b-button>
      </template>
      <template #cell(date_create)="data">
        {{$api.timestampToDate(data.value)}}
      </template>
    </Datatable>
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
export default {
  name: "UtilisateurListe",
  components: {
    Datatable
  },
  watch:{
  },
  data(){
    return{
      fields: [
        {
          key: 'id',
          label: 'Ref',
          sortable:true,
        },
        {
          key: 'nom',
          label: 'Nom',
          sortable:false,
        },
        {
          key: 'prenom',
          label: 'Prenom',
          sortable:false,
        },
        {
          key: 'email',
          label: 'Email',
          sortable:false,
        },
        {
          key: 'role_id',
          label: 'Role',
          sortable:false,
        },
        {
          key: 'status_id',
          label: 'Statut',
          sortable:false,
        },
        {
          key: 'date_create',
          label: 'Date de creation',
          sortable:false,
        },
      ],
      sort:{
        key: 'id',
        value: 'DESC'
      }
    }
  },
  computed:{
  },
  methods: {
  },
  beforeMount() {
  },
  mounted() {
  }
}
</script>
<style>
</style>
